#ActivitiesContainer {
  margin: 19px;
  height: 91vh;
  overflow-y: auto;
  overflow-x: auto;
}

.ActivityDateTimePicker {
  display: inline-block;
  margin: 10px;
}

#ActivityDescriptionContainer {
  margin-top: 19px;
  margin-bottom: 16px;
  width: 97%;
}

#ActivityDescriptionTextArea {
  width: 100%;
}

#Leaderboard .LeaderboardItem {
  margin: 4px;
}

#InternsNumQuestion {
  margin-right: 19px;
}

#InternsNumAvgLabel {
  margin-left: 19px;
}

.ChipContent {
  font-size: 19px;
}

#InternsNumFormControl {
  margin-top: 16px;
}

#GreenText {
  color: green;
}

div.MuiTooltip-tooltip {
  box-shadow: 0px 13px 17px -9px rgba(0, 0, 0, 0.4),
    0px 26px 40px 4px rgba(0, 0, 0, 0.2), 0px 10px 48px 9px rgba(0, 0, 0, 0.13);
  background-color: white;
  color: black;
  font-size: 13px;
}

button.ProjectButton {
  background-color: rgb(0, 121, 202);
}

button.ProjectButton:hover {
  background-color: rgb(16, 160, 255);
}
