#SchedulePageContainer {
  width: 94vw;
  height: 100vh;
  margin: 3vw;
  overflow-x: auto;
  overflow-y: auto;
}

.DateDescription {
  font-size: 19px;
  margin-top: 19px;
  margin-bottom: 19px;
}

.DateText {
  font-style: italic;
  font-weight: bold;
  margin-bottom: 10px;
}

#TimeSlotsTable {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  width: 100%;
  height: 100vh;
}

#ScheduleSelectorContainer {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 250px;
}

.input.ValidatedInput {
  width: 250px !important;
  margin-right: 19px;
}

#SignBtnContainer {
  position: fixed;
  bottom: 0px;
  left: 46%;
  /* margin-top: 10px; */
  margin-bottom: 10px;
  /* text-align: center; */
}

.SubmitButton {
  margin-top: 19px;
  font-size: 19px;
  padding: 10px 19px 10px 19px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.ScheduleCell {
  width: 100%;
  height: 25px;
  text-align: center;
  cursor: pointer;
}
.SelectedCell {
  background-color: rgba(89, 154, 242, 1);
}

.SelectableCell {
  background-color: #dbffeb;
}

.UnselectedCell {
  background-color: #dbedff;
}

.UnavailableCell {
  cursor: not-allowed;
  background-color: #c1c1c1;
}

#WarningPoints {
  margin-left: -19px;
  font-size: 19px;
}
