#AddAdministrator .TextField,
#AddAdministrator .Select {
  margin: 10px;
  min-width: 220px;
}

#VoteOtherFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 19px;
}

#VoteOtherUpVoteBtn {
  background-color: rgb(112, 187, 0);
  margin: 0;
}

#VoteOtherDownVoteBtn {
  background-color: rgb(129, 198, 255);
  margin: 0;
}
