#PassageContainer {
  border: 1px solid orange;
  width: 100%;
  height: 100%;
}

#PassageFrame {
  width: 100%;
  height: 100%;
}

#QuestionsContainer,
#NullPassageInstructions {
  border: 1px solid orange;
  padding: 19px;
  overflow-y: auto;
  overflow-x: hidden;
}

#QuestionsInstructions {
  padding: 25px;
}

#QuestionsInstructions p {
  font-size: 16px;
}

#StartTestContainer,
#ReloadContainer {
  margin-top: 19px;
}

#StartTestButton {
  background-color: rgb(112, 187, 0);
}

#FreeRecallTextArea {
  width: 100% !important;
}

#ScoresContainer {
  display: grid;
  grid-template-columns: auto 10px auto;
  width: 100%;
  margin-top: 19px;
}

.ScoreTitle {
  font-weight: bold;
  font-size: 1.3vw;
  margin-bottom: 10px;
}

.ScoreNum {
  font-style: italic;
  font-size: 1.3vw;
  margin-bottom: 10px;
}

#StartTestButton.Disabled {
  background-color: rgb(199, 199, 199);
}
