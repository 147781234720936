.schema-generation {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: calc(100% - 105px);
}

.section {
  width: 100%;
  display: flex;
  height: 100%;
}

.section .blocks {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* for mozilla firefox */
  min-height: 0;
}

.section .blocks.search-box {
  width: 50%;
  height: 99%;
}

.section .blocks.result-box {
  width: 50%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.phrases-box {
  display: grid;
  grid-template-columns: 10% 80% 10%;
}

.result-box .header {
  font-family: Roboto;
  font-size: 23px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.15px;
}

.result-box .subtitle {
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.query-block {
  overflow: auto;
  padding: 0 10px;
  margin-bottom: 80px;
}