@import url(./animations.css);
#UMICH_Logo {
  margin-right: 10px;
}

#App70,
#App50 {
  display: grid;
  grid-template-rows: 100%;
  width: 100%;
  height: 100vh;
}
#App70 {
  grid-template-columns: 70% 30%;
}
#App50 {
  grid-template-columns: 50% 50%;
}

#Timer {
  font-size: 19px;
  font-style: italic;
  text-align: center;
}

#VotedToday,
#UpVotedDays,
#ExperimentPoints,
#IntellectualPoints {
  padding-left: 10px;
  padding-right: 10px;
}

/* .MuiToolbar-root .MuiIconButton-edgeStart {
  padding: 0px;
  margin-right: 19px;
} */

.UpVotedTodayEarned {
  background-color: rgba(255, 255, 255, 0.4);
}

button.ActiveNavLink {
  text-align: left;
  margin-bottom: -4px;
  padding-bottom: 4px;
  border-bottom: 2.5px solid orange;
  color: white;
}

button.NavLink {
  text-align: left;
  text-decoration: none;
  color: white;
}

button.ActiveNavLink:hover,
button.NavLink:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

#LogoutText {
  margin-left: 10px;
}

#ProjectLabel {
  margin: 13px 0px -4px -10px;
}

#ProjectSelector {
  color: white;
}

#DataVisualization {
  height: 470px;
  width: 100%;
}

.Button.SubmitButton {
  background-color: rgb(112, 187, 0);
}

.Button.ClearButton {
  background-color: rgb(255, 94, 0);
}

.Button.SubmitButton.Disabled {
  background-color: rgb(163, 163, 163);
}

.IconImage {
  display: inline-block;
  margin-bottom: -10px;
}
.IconText {
  display: inline-block;
  margin-bottom: -10px;
}

.YellowLabel {
  font-style: italic;
  color: yellow;
}

.VoteActivityAlert {
  margin: 10px;
}

.VoteActivityPaper {
  padding: 19px;
  margin: 10px;
}

.DataGridBox {
  height: 670px;
  width: 100%;
}

.ColumnsAuto_Auto {
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
}

.Columns40_60 {
  display: grid;
  grid-template-columns: 40% 60%;
  width: 100%;
}

.Error {
  color: red;
  margin: 10px;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  padding: 10px;
}

button.Button {
  color: white;
  font-size: 16px;
  margin: 19px;
  padding: 10px 19px 10px 19px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
button.Button.Green {
  background-color: rgb(112, 187, 0);
}
button.Button.Red {
  background-color: rgb(255, 70, 45);
}

.rive-canvas {
  width: inherit;
  height: inherit;
  position: absolute;
}

.rive-canvas-hidden {
  opacity: 0;
  /* display: none; */
  /* transition: 1s; */
}
.page-wrapper {
  max-width: 980px;
  margin: auto;
  border: "solid 2px pink";
}
