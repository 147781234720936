.DoubleInputRow {
  display: grid;
  grid-template-columns: auto 34px auto;
}

.FormInputs .input.PleaseSpecify {
  margin-top: 16px;
  margin-bottom: -10px;
}

#PersonalInfoContainer {
  display: grid;
  grid-template-rows: auto 16px auto 16px auto 16px auto 16px auto 16px auto;
  grid-template-columns: 100%;
  margin: 19px;
}
