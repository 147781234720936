#LifeLoggerContainer {
  display: block;
  overflow-y: auto;
  overflow-x: auto;
  height: 91vh;
  margin: 19px;
}

#Summary {
  display: inline-block;
  margin-right: 25px;
}

.TaskChip {
  display: inline-block;
  padding: 4px;
}

#FooterGap {
  width: 100vw;
  height: 100px;
}

#TimerContainer {
  display: inline-block;
}

#Timer {
  display: inline-block;
}
