.input.validatedInput {
  margin-top: 10px;
  margin-bottom: 10px;
}

.input.validatedInput.MuiTextField-root {
  width: 100%;
}

.input.validatedInput .MuiInputBase-input {
  padding-left: 13px;
  padding-right: 13px;
  /* padding-top: 5.5px; */
  border-bottom: none;
}

.input.validatedInput.validated .Mui-focused .MuiInputBase-input {
  border-bottom: none;
  box-shadow: none;
}

.input.validatedInput .MuiFormHelperText-root {
  color: #f44336;
}
