.query-builder {
  padding: 25px;
  margin-bottom: 25px;
  background: #F8F8F8 !important;
}

.query-builder.not {
 background: #FEF3EAB2 !important;
}

.query-builder .title {
  font-family: Roboto;
  font-size: 19px;
  /* font-weight: 700; */
  line-height: 29px;
  letter-spacing: 0.15px;
  text-align: left;
}

.query-builder .subtitle {
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
}

.query-builder .keyword {
  display: flex;
  margin: 18px 0;
  justify-content: space-between;
}

.query-builder .keyword-delete {
  color: red;
}