#ConsentContainer,
#Auth {
  margin: 0px 13px 13px 13px;
  padding: 9px;
  padding-bottom: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

#ConsentContainer h2 {
  text-align: center;
}

#FullnameForm,
#EmailForm {
  width: 100%;
}

#SignButtonContainer {
  margin: auto;
  text-align: center;
  margin-top: 10px;
}

#ForgotPasswordEmailButton,
#SignButton {
  background-color: rgb(112, 187, 0);
}

#ForgotPasswordEmailButton.Disabled,
#SignButton.Disabled {
  background-color: rgb(163, 163, 163);
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
