g.type-PO>rect {
  fill: #4caf50;
}



.type-ST text,
.type-NO text,
.type-PO text,
.type-DF text {
  fill: white;
}

.node rect {
  stroke: #ecf3ed;
  fill: #fff;
  stroke-width: 1.5px;
}

.edgePath path.path {
  stroke: transparent;
  fill: transparent;
  stroke-width: 1.3px;
}

text.custom-text-color {
  fill: #000000; 
}
