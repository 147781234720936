#QuestionContainer {
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  margin: "0 auto";
  margin-left: -10px;
}

#QuestionContainer button.Button {
  margin: 0px;
}

#QuestionMeta {
  margin-left: 10px;
  display: inline;
  font-style: italic;
  font-size: 19px;
}

#QuestionHeaderSeparator {
  margin-top: 10px;
  margin-bottom: 19px;
}

#QuestionContent {
  margin-right: -7px;
}

#QuestionContent legend {
  line-height: 22px;
}

#ChoiceGroup {
  margin-right: -19px;
}

.QuestionChoice {
  margin-top: 10px;
  padding-right: 13px;
}

.QuestionChoice:hover,
.QuestionChoice:focus {
  background-color: rgb(226, 226, 226);
}

#QuestionFooter {
  margin-bottom: 70px;
  margin-top: 19px;
}

#QuestionIconBtn {
  background-color: orange;
  border-radius: 50%;
  margin-left: 19px;
  min-width: 49px;
  width: 49px;
  height: 49px;
  pointer-events: none;
  box-shadow: none;
  font-size: 40px;
  color: white;
}

#QuestionNextBtn {
  background-color: rgb(112, 187, 0);
  float: right;
}

#QuestionPreviousBtn {
  background-color: rgb(129, 198, 255);
  float: left;
}

#QuestionSubmitContainer {
  width: 100%;
  text-align: center;
}

#QuestionSubmitBtn {
  margin-bottom: 70px;
  background-color: orange;
}
#AddCodeButton{
  background-color: rgb(112, 187, 0);
  float: right;
}
#AddCodeButton.Disabled,
#QuestionNextBtn.Disabled,
#QuestionPreviousBtn.Disabled,
#QuestionSubmitBtn.Disabled {
  background-color: rgb(199, 199, 199);
}


